import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const Blocks = memo(function Blocks({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Items>
        {items.map((item, index) => (
          <Item key={index} {...item} index={index} />
        ))}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  padding: 7vw 10vw 10vw;

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -4.16vw;
  margin-bottom: -6.66vw;

  @media (max-width: 991px) {
    margin-left: 0;
    display: block;
  }

  @media (min-width: 992px) {
    > div:first-of-type {
      width: 100%;

      picture {
        height: 34vw;
      }
    }
  }
`
